<template>
  <div class="flex w-full py-16" :class="white ? 'bg-white' : 'bg-offwhite'">
    <div
      class="w-9/12 mobile:w-full mx-auto flex flex-col items-center mobile:px-4 px-16"
    >
      <span
        class="mobile:text-center text-5xl font-semibold mobile:text-4xl mobile:mx-2"
        :class="text ? 'mb-4' : 'mb-8'"
      >
        Partnereid kõikjalt
      </span>
      <span v-if="text" class="mb-8"
        >Meie partnerid on erinevatest sektoritest mikro- ja väikettevõtted, kes
        näevad digitaliseerimises potentsiaali</span
      >
      <div class="flex w-full mobile:flex-wrap">
        <div
          class="w-1/4 mobile:w-full flex flex-col p-5 m-1 items-center justify-center"
          :class="white ? '' : 'bg-white rounded-gwdhalf shadow'"
        >
          <img src="/proto-front/partner-1.jpg" />
        </div>
        <div
          class="w-1/4 mobile:w-full flex flex-col p-5 m-1 items-center justify-center"
          :class="white ? '' : 'bg-white rounded-gwdhalf shadow'"
        >
          <img src="/proto-front/partner-2.png" />
        </div>
        <div
          class="w-1/4 mobile:w-full flex flex-col p-5 m-1 items-center justify-center"
          :class="white ? '' : 'bg-white rounded-gwdhalf shadow'"
        >
          <img src="/proto-front/sl-roof.png" />
        </div>
        <div
          class="w-1/4 mobile:w-full flex flex-col p-5 m-1 items-center justify-center"
          :class="white ? '' : 'bg-white rounded-gwdhalf shadow'"
        >
          <img src="/proto-front/partner-4.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    }
  }
};
</script>
