<template>
  <div
    class="w-full flex flex-col items-center py-12 px-6 bg-offwhite mobile:px-4"
  >
    <div
      class="w-9/12 flex flex-col mx-auto mobile:mx-2 items-center mobile:w-full"
    >
      <span class="text-5xl font-semibold mobile:text-center"
        ><span class="text-green">Mugav</span> kasutada.</span
      >
      <span class="text-4xl font-semibold mb-6 mobile:text-center"
        ><span class="text-green">Lihtne</span> õppida.</span
      >
      <div class="flex w-full mobile:flex-wrap">
        <div
          class="w-1/3 mobile:w-full p-3 flex flex-col items-center mobile:px-0"
        >
          <div class="w-full flex justify-center items-center">
            <img src="/proto-front/e2u-1.png" class="proto-e2u-icon" />
          </div>

          <div
            class="bg-white rounded-md flex flex-col p-5 h-full shadow w-10/12 mobile:w-full"
          >
            <h4 class="self-center mobile:self-start mb-2">
              1. Loo ettevõtte profiil
            </h4>
            <span class="px-2 mobile:px-0"
              >Loo kasutaja ning lisa oma ettevõte - see võtab umbes 1 minuti.
              Sul on koheselt ligipääs kõikidele Gewodo tööriistadele ning saad
              hetkega kasutamist alustada.</span
            >
          </div>
        </div>
        <div
          class="w-1/3 mobile:w-full p-3 flex flex-col items-center mobile:px-0"
        >
          <div class="w-full flex justify-center items-center">
            <img src="/proto-front/e2u-2.png" class="proto-e2u-icon" />
          </div>
          <div
            class="bg-white rounded-md flex flex-col p-5 h-full shadow w-10/12 mobile:w-full"
          >
            <h4 class="self-center mobile:self-start mb-2">2. Too töö üle</h4>
            <span class="px-2 mobile:px-0"
              >Kanna sisse töötajad ning kliendid ja saada neile liitumiseks
              link. Lisa endale tööprojekte läbi lihtsa projektivormi. Pane
              kirja kõik projektiga seonduv ja töö võibki alata.</span
            >
          </div>
        </div>
        <div
          class="w-1/3 mobile:w-full p-3 flex flex-col items-center mobile:px-0"
        >
          <div class="w-full flex justify-center items-center">
            <img src="/proto-front/e2u-3.png" class="proto-e2u-icon" />
          </div>
          <div
            class="bg-white rounded-md flex flex-col p-5 h-full shadow w-10/12 mobile:w-full"
          >
            <h4 class="self-center mobile:self-start mb-2">
              3. Asu sujuvalt tööle
            </h4>
            <span class="px-2 mobile:px-0"
              >Vaid mõne klikiga saad Sa lisada projektidele ülesandeid ja
              ülesannetele töötajaid. Lisaks saad ka sisestada faile, teha
              pakkumisi ja arveid ning suhelda klientidega. Nii lihtne see
              ongi!</span
            >
          </div>
        </div>
      </div>
      <div
        class="flex w-1/2 mobile:w-full px-10 mobile:px-16 medium:px-8 flex-col"
      >
        <h2 class="self-center text-center text-3xl mb-4">
          Huvitatud?
        </h2>
        <transition name="fade">
          <span
            v-if="$v.$error && !$v.email.email && $v.email.required"
            class="text-danger text-center font-medium"
            >Meiliaadress ei vasta nõuetele</span
          >
        </transition>
        <transition name="fade"
          ><span
            v-if="$v.$error && !$v.email.required"
            class="text-danger text-center font-medium"
            >Palun sisestage meiliaadress</span
          ></transition
        >
        <div
          v-if="success"
          class="w-full mb-2 text-green font-medium  justify-center items-center text-center"
        >
          Sõnum saadetud! Vastame teile esimesel võimalusel!
        </div>
        <div
          v-if="error"
          class="w-full mb-5 text-danger font-medium self-center justify-center items-center text-center"
        >
          <span
            >Sõnumi saatmine ebaõnnestus! Proovige uuesti või võtke meiega
            ühendust aadressil
            <a href="mailto:info@gewodo.com" class="hover:text-lightgrey"
              >info@gewodo.com</a
            >.</span
          >
        </div>
        <div class="flex duration-100 justify-center items-center">
          <router-link
            :to="$store.state.auth.status.loggedIn ? '/calendar' : '/register'"
            class="w-full flex items-center justify-center"
          >
            <button class="new-button-green text-3xl">
              <span class="label py-2 px-5">Proovi TASUTA</span>
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
import { required, email } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      email: "",
      success: false,
      error: false,
      emailSelected: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.state.loading = true;
        const data = { sender_email: this.email, type: "user" };
        this.apiRequest("core/email/interest/", "post", false, data)
          .then(
            res => {
              this.$store.state.loading = false;
              if (res.status === 201) {
                this.success = true;
              } else {
                this.error = true;
              }
            },
            err => {
              this.$store.state.loading = false;
              if (err) {
                this.error = true;
              }
            }
          )
          .catch(err => {
            this.$store.state.loading = false;
            if (err) {
              this.error = true;
            }
          });
      }
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
};
</script>

<style lang="scss">
.proto-e2u-icon {
  max-height: 8rem;
  @apply mb-3;
}
</style>
